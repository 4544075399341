.loading-logo
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: center

  > img
    height: 40px
    width: auto
    margin-bottom: 24px
